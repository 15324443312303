import { useTranslation } from 'react-i18next';
import { Socials } from '../components';

const Landing = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}
    >
      <div style={{ top: '35%', position: 'absolute' }}>
        <h2 style={{ fontSize: 60, margin: 0 }}>{t('landing.title')}</h2>
        {/* <div style={{ margin: '1rem' }}> */}
        {/* <CVDownload /> */}
        {/* </div> */}
        <Socials />
      </div>
    </div>
  );
};

export default Landing;
