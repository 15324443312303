import * as React from 'react';
import { Suspense } from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './i18n';
import {
  Route as RouterRoute,
  Routes as RouterRoutes,
  useLocation,
} from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Loading } from './components';
import { Landing, NotFound } from './Pages';

import { AnimatePresence, motion } from 'framer-motion';

const App: React.FC = () => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = false;

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#b02429',
          },
          secondary: {
            main: '#24b0ab',
          },
        },
      }),
    [prefersDarkMode]
  );
  const location = useLocation();

  type page = { path: string; element: React.ReactElement<any> };
  const pageRoutes: page[] = [
    { path: '*', element: <NotFound /> },
    { path: '/', element: <Landing /> },
  ];
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <AnimatePresence exitBeforeEnter>
          <RouterRoutes location={location} key={location.pathname}>
            {pageRoutes.map((page) => {
              return (
                <RouterRoute
                  path={page.path}
                  element={<AnimateWrapper element={page.element} />}
                />
              );
            })}
          </RouterRoutes>
        </AnimatePresence>
      </Suspense>
    </ThemeProvider>
  );
};

const AnimateWrapper: React.FC<{ element: React.ReactElement<any> }> = (
  props
) => {
  const pageMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.25 } },
    exit: { opacity: 0, transition: { duration: 0.25 } },
  };

  return (
    <motion.div
      initial='initial'
      animate='animate'
      exit='exit'
      variants={pageMotion}
    >
      {props.element}
    </motion.div>
  );
};

export default App;

// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
